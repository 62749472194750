<template>
  <ui-form @submit="handleSubmit" @cancel="handleCancel">
    <ui-form-field :label="$t('fields.email')" rules="required|email">
      <el-input type="email" v-model="user.email"></el-input>
    </ui-form-field>

    <ui-form-field :label="$tc('models.role')" rules="required">
      <role-field v-model="user.role" :restricted="true"></role-field>
    </ui-form-field>

    <ui-form-field :label="$tc('models.department')" v-if="user.department">
      <div>{{ user.department.name }}</div>
    </ui-form-field>

    <ui-form-field :label="$tc('models.course', 2)" v-if="user.department">
      <course-field
        :departmentId="user.department.id"
        :disabled="!user.role"
        @input="onCourseChange"
      ></course-field>
    </ui-form-field>
  </ui-form>
</template>

<script>
import UiForm from '@/components/ui/UiForm';
import UiFormField from '@/components/ui/UiFormField';
import RoleField from '@/components/form/RoleField';
import CourseField from '@/components/form/CourseField';

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },

  components: {
    UiForm,
    UiFormField,
    RoleField,
    CourseField
  },

  methods: {
    onCourseChange(val) {
      this.user.courses = val;
    },

    handleSubmit() {
      this.$emit('submit');
    },

    handleCancel() {
      this.$emit('cancel');
    }
  }
};
</script>
