<template>
  <div>
<!--  <ui-form @cancel="handleCancel">-->
    <!-- Department Name -->
    <ui-form-field :label="$tc('models.department')" v-if="user.department">
      <div>{{ user.department.name }}</div>
    </ui-form-field>

    <!-- Course Picker -->
    <ui-form-field :label="$tc('models.course', 2)" v-if="user.department">
      <course-field
          :departmentId="user.department.id"
          :disabled="false"
          @input="onCourseChange"
      ></course-field>
    </ui-form-field>

    <!-- User email input -->
    <section>
      <ui-form-field :label="$t('creation.import_label')">
        <el-input v-model="userEmailsInputString"></el-input>
      </ui-form-field>
      <!-- Buttons -->
      <div class="right">
        <ui-link
            type="primary"
            :disabled="!regex.test(userEmailsInputString) || userEmailsInputString.trim() === ''"
            uppercase
            @click="handleVerify"
        >
          {{ $t('actions.verify') }}
        </ui-link>
        <ui-link
            type="success"
            class="ml-2"
            uppercase
            @click="handleSaveImport"
            :disabled="!verified || !usersList.length > 0"
        >
          {{ $t('actions.save') }}
        </ui-link>
        <ui-link
            class="ml-2"
            type="text"
            @click="handleCancel"
        >
          {{ $t('actions.cancel') }}
        </ui-link>
      </div>
      <!-- Table list -->
      <department-user-creator-table
          class="mt-4"
          v-if="usersList.length > 0"
          :emails="usersList"
          @onRemoveItem="handleRemoveItem"
      />
    </section>
<!--  </ui-form>-->
  </div>
</template>

<script>
import UiFormField from '@/components/ui/UiFormField';
import CourseField from '@/components/form/CourseField';
import DepartmentUserCreatorTable from "./DepartmentUserCreatorTable";
import UiLink from '@/components/ui/UiLink';

export default {
  name: "DepartmentUserImportForm",
  props: {
    user: {
      type: Object,
      required: true
    }
  },

  components: {
    DepartmentUserCreatorTable,
    // UiForm,
    UiFormField,
    CourseField,
    UiLink
  },

  data() {
    return {
      userEmailsInputString: '',
      courses: [],
      usersList: [],
      verified: false,
      regex: new RegExp(/^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s?)*$/m)
    }
  },

  methods: {
    onCourseChange(val) {
      this.courses = val;
    },

    handleCancel() {
      this.$emit('cancel');
    },

    handleVerify() {
      const valueString = this.userEmailsInputString;
      this.usersList = this.getEmailList(valueString);
      this.verified = true;
    },

    getEmailList(valueString) {
      const emails = valueString.split(' ');
      const list = [];
      emails.forEach(email => {
        if (email !== ' ' && email !== '') {
          list.push(email);
        }
      });
      return list;
    },

    handleRemoveItem(item) {
      this.usersList = this.usersList.filter(
          email => email !== item
      );
    },

    handleSaveImport() {
      this.$emit('onSaveImport', {
        departmentId: this.user.department.id,
        courses: this.courses,
        emails: this.usersList
      });
    }
  }
}
</script>

<style scoped>

</style>